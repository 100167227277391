import SlideShowGallery_SlideShowTextFloatingComponent from '../components/NativeGalleries/SlideShowGallery/viewer/skinComps/SlideShowTextFloating/SlideShowTextFloating.skin';
import SlideShowGallery_SlideShowTextFloatingController from '../components/NativeGalleries/SlideShowGallery/viewer/SlideShowGallery.controller';


const SlideShowGallery_SlideShowTextFloating = {
  component: SlideShowGallery_SlideShowTextFloatingComponent,
  controller: SlideShowGallery_SlideShowTextFloatingController
};


export const components = {
  ['SlideShowGallery_SlideShowTextFloating']: SlideShowGallery_SlideShowTextFloating
};

